import { useEffect, useState } from "react";
import styles from "./Hero.module.css";
import axios from "axios";
import {convertToTargetTimezone} from "../../utils/convertTimeZone";
import {ReactComponent as MoonIcon} from "./moonIcon.svg";

const cn = require('classnames')

function Meteo() {
  const [data, setData] = useState({
    air: null,
    snow: null,
    humidity: null,
    wind: null,
  });
  const [isDayTime, setDayTime] = useState(true)

  const getMeteoData = () => {
    axios.get("https://meteo.vorobi.ski/get").then((response) => {
      const weatherData = response.data[0] || null;

      if (weatherData) {
        if (typeof weatherData['windspeedmph'] === "string") {
          weatherData['windspeedmph'] = parseFloat(weatherData['windspeedmph']).toFixed(1)
        }

        setData({
          air: weatherData['tempf'],
          snow: weatherData['tempsnow'] || '-',
          humidity: weatherData['humidity'],
          wind: weatherData['windspeedmph'],
        });
      }
    }).catch(err => {
      if (err) {
        throw new Error(err.message)
      }
    })
  }
  const getDaytimeData = () => {
    axios.get("https://api.bf5.ru/sun?lat=56.1175089&lon=93.4422486").then(response=>{
      const sunData = response.data || null
      const calculateTimeSec = (time) => {
        const timeArr = time.toString().split(':')
        return (parseInt(timeArr[0]) * 60**2) + (parseInt(timeArr[1]) * 60)
      }

      if (sunData) {
        sunData['sunrise'] = convertToTargetTimezone(sunData['sunrise'], +4)
        sunData['sunset'] = convertToTargetTimezone(sunData['sunset'], +4)

        // console.log(sunData)
        const sunriseTime = sunData['sunrise']
        const sunriseTimeSec = calculateTimeSec(sunriseTime)

        const sunsetTime = sunData['sunset']
        const sunsetTimeSec = calculateTimeSec(sunsetTime)

        const longitudeTime = sunData['longitude']
        const longitudeTimeSec = calculateTimeSec(longitudeTime)
        // const maxSunTimeSec = sunriseTimeSec + longitudeTimeSec

        const date = new Date(Date.parse(new Date().toLocaleString('en-EN', {
          timeZone: 'Asia/Krasnoyarsk'
        })))
        const currentTime = `${date.getHours()}:${date.getMinutes()}`
        const currentTimeSec = calculateTimeSec(currentTime)

        if (currentTimeSec > sunriseTimeSec && currentTimeSec <= sunsetTimeSec) {
          const currentDayTimeSec = currentTimeSec - sunriseTimeSec
          const currentDayTimePct = Math.ceil(currentDayTimeSec / longitudeTimeSec * 100)

          const sunElement = document.querySelector('#sunElement')
          if  (sunElement) {
            const rotationDeg = -8 + currentDayTimePct/100 * 196;
            sunElement.style.transform = `translate(-50%, -50%) rotate(${rotationDeg}deg)`
          }
          return;
        }

        setDayTime(false)
      }
    }).catch(err => {
      if (err) {
        throw new Error(err.message)
      }
    })
  }

  useEffect(() => {
    const getMeteoDataIntervalID = setInterval(() => {
      getMeteoData();
      getDaytimeData();
    }, 15000);

    getMeteoData();
    getDaytimeData();

    return () => clearInterval(getMeteoDataIntervalID)
  }, []);

  return (
    <div className={styles.Meteo}>
      <div className={styles.dayTimeFrame}>
        {
          !isDayTime && <div className={styles.moonWrapper}>
                          <div className={styles.moonBackground}></div>
                          <MoonIcon className={styles.moonIcon}></MoonIcon>
                        </div>
        }
        <div className={cn(styles.dayTimeScale, !isDayTime && styles.dayTimeScale_dark )}>
          { isDayTime && <div id='sunElement' className={styles.scaleSun}></div> }
        </div>
        <span className={styles.meteoAirTemp}>{data.air} &deg;C</span>
      </div>
      {/*<div className={styles.MeteoIcon}>{data.air} &deg;C</div>*/}
      <div className={styles.MeteoInfo}>
        <div className={styles.MeteoNames}>
          <div className={styles.MeteoName}>t снег:</div>
          <div className={styles.MeteoName}>влаж:</div>
          <div className={styles.MeteoName}>ветер:</div>
        </div>
        <div className={styles.MeteoValues}>
          <div className={styles.MeteoValue}>{data.snow} {data.snow !== '-' && <span>&deg;C</span>}</div>
          <div className={styles.MeteoValue}>{data.humidity} %</div>
          <div className={styles.MeteoValue}>{data.wind} м/с</div>
        </div>
      </div>
    </div>
  );
}

export default Meteo;
